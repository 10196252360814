<script>
import VueApexCharts from 'vue-apexcharts';
import { BAvatar, BCard, BCardBody, BCol, BRow } from 'bootstrap-vue';
import api from '@/services/api';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart';
import { abbreviateNumber, numberFormat, prefixZeroToNum } from '@/util/helper';
import { formatToPrice } from '@/util/filter/filter.util';

export default {
    name: 'Home',
    components: {
        StatisticCardWithLineChart,
        ProgressLinear,
        apexchart: VueApexCharts,
        BRow,
        BCol,
        BAvatar,
        BCard,
        BCardBody
    },
    data: () => ({
        totalStatistics: {
            subscribers: {
                chartOptions: {
                    grid: {
                        show: false,
                        padding: {
                            left: 0,
                            right: 0
                        }
                    },
                    chart: {
                        toolbar: {
                            show: false
                        },
                        sparkline: {
                            enabled: true
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 2.5
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 0.9,
                            opacityFrom: 0.5,
                            opacityTo: 0.2,
                            stops: [0, 80, 100]
                        }
                    },
                    xaxis: {
                        type: 'numeric',
                        lines: {
                            show: false
                        },
                        axisBorder: {
                            show: false
                        },
                        labels: { show: false }
                    },
                    yaxis: [
                        {
                            y: 0,
                            offsetX: 0,
                            offsetY: 0,
                            padding: {
                                left: 0,
                                right: 0
                            }
                        }
                    ],
                    tooltip: {
                        x: { show: false },
                        y: {
                            formatter: (seriesName) => `${abbreviateNumber(seriesName)}`
                        }
                    },
                    theme: {
                        monochrome: {
                            enabled: true,
                            color: '#7367f0',
                            shadeTo: 'light',
                            shadeIntensity: 0.65
                        }
                    },
                    responsive: [
                        {
                            breakpoint: 10000,
                            options: {
                                chart: {
                                    height: 130
                                }
                            }
                        },
                        {
                            breakpoint: 1200,
                            options: {
                                chart: {
                                    height: 100
                                }
                            }
                        }
                    ]
                }
            },
            invoices: {
                series: [
                    {
                        name: 'Charged',
                        data: []
                    },
                    {
                        name: 'Uncharged',
                        data: []
                    }
                ],
                chartOptions: {
                    grid: {
                        show: false,
                        padding: {
                            left: 0,
                            right: 0
                        }
                    },
                    chart: {
                        toolbar: {
                            show: false
                        },
                        sparkline: {
                            enabled: true
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: ['#00e296', '#F44336'],
                    stroke: {
                        curve: 'smooth',
                        width: 2.5,
                        colors: ['#00e296', '#F44336']
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 0.9,
                            opacityFrom: 0.5,
                            opacityTo: 0.5,
                            stops: [0, 80, 100]
                        },
                        colors: ['#00e296', '#F44336']
                    },
                    xaxis: {
                        // type: 'numeric',
                        // lines: {
                        //   show: false,
                        // },
                        // axisBorder: {
                        //   show: false,
                        // },
                        // labels: { show: false },
                        categories: []
                    },
                    yaxis: [
                        {
                            y: 0,
                            offsetX: 0,
                            offsetY: 0,
                            padding: {
                                left: 0,
                                right: 0
                            }
                        }
                    ],
                    tooltip: {
                        x: { show: false },
                        y: {
                            formatter: (seriesName) => `${abbreviateNumber(seriesName)}`
                        }
                    },
                    markers: {
                        colors: ['#00e296', '#F44336']
                    },
                    responsive: [
                        {
                            breakpoint: 10000,
                            options: {
                                chart: {
                                    height: 130
                                }
                            }
                        },
                        {
                            breakpoint: 1200,
                            options: {
                                chart: {
                                    height: 100
                                }
                            }
                        }
                    ]
                },
                overall: 0,
                loaded: false
            },
            gifts: {
                series: [
                    {
                        name: 'Gifts',
                        data: []
                    }
                ],
                chartOptions: {
                    grid: {
                        show: false,
                        padding: {
                            left: 0,
                            right: 0
                        }
                    },
                    chart: {
                        toolbar: {
                            show: false
                        },
                        sparkline: {
                            enabled: true
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 2.5
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 0.9,
                            opacityFrom: 0.5,
                            opacityTo: 0.2,
                            stops: [0, 80, 100]
                        }
                    },
                    xaxis: {
                        type: 'numeric',
                        lines: {
                            show: false
                        },
                        axisBorder: {
                            show: false
                        },
                        categories: [],
                        labels: { show: false }
                    },
                    yaxis: [
                        {
                            y: 0,
                            offsetX: 0,
                            offsetY: 0,
                            padding: {
                                left: 0,
                                right: 0
                            }
                        }
                    ],
                    tooltip: {
                        x: { show: false }
                    },
                    theme: {
                        monochrome: {
                            enabled: true,
                            color: '#7367f0',
                            shadeTo: 'light',
                            shadeIntensity: 0.65
                        }
                    },
                    responsive: [
                        {
                            breakpoint: 10000,
                            options: {
                                chart: {
                                    height: 130
                                }
                            }
                        },
                        {
                            breakpoint: 1200,
                            options: {
                                chart: {
                                    height: 100
                                }
                            }
                        }
                    ]
                },
                loaded: false
            }
        },
        subscribersVerification: {
            loaded: false,
            chartOptions: {
                chart: {
                    type: 'donut',
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '12px',
                        colors: ['#7367f0']
                    },
                    background: {
                        enabled: true,
                        padding: 5,
                        borderRadius: 2,
                        borderWidth: 1,
                        opacity: 1
                    }
                },
                legend: {
                    show: false
                },
                labels: ['Verified', 'Unverified'],
                stroke: { width: 0 },
                colors: ['#00e296', '#F44336', '#7367f0'],
                grid: {
                    padding: {
                        right: -20,
                        bottom: -10,
                        left: -20
                    }
                },
                plotOptions: {
                    pie: {
                        // startAngle: -10,
                        donut: {
                            labels: {
                                show: false,
                                value: {
                                    offsetY: 0,
                                    formatter(val) {
                                        // eslint-disable-next-line radix
                                        return `${parseInt(val)}`;
                                    }
                                },
                                total: {
                                    show: false,
                                    offsetY: -5
                                }
                            }
                        }
                    }
                },
                responsive: [
                    {
                        breakpoint: 10000,
                        options: {
                            chart: {
                                height: 130
                            }
                        }
                    },
                    {
                        breakpoint: 1200,
                        options: {
                            chart: {
                                height: 100
                            }
                        }
                    }
                ]
            }
        },

        paynetStats: {
            loaded: false,
            chartOptions: {
                chart: {
                    type: 'donut',
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '12px',
                        colors: ['#7367f0']
                    },
                    background: {
                        enabled: true,
                        padding: 5,
                        borderRadius: 2,
                        borderWidth: 1,
                        opacity: 1
                    }
                },
                legend: {
                    show: false
                },
                labels: ['Deposit', 'Expenses'],
                stroke: { width: 0 },
                colors: ['#00e296', '#F44336', '#7367f0'],
                grid: {
                    padding: {
                        right: -20,
                        bottom: -10,
                        left: -20
                    }
                },
                plotOptions: {
                    pie: {
                        // startAngle: -10,
                        donut: {
                            labels: {
                                show: false,
                                value: {
                                    offsetY: 0,
                                    formatter(val) {
                                        // eslint-disable-next-line radix
                                        return `${parseInt(val)}`;
                                    }
                                },
                                total: {
                                    show: false,
                                    offsetY: -5
                                }
                            }
                        }
                    }
                },
                responsive: [
                    {
                        breakpoint: 10000,
                        options: {
                            chart: {
                                height: 190
                            }
                        }
                    },
                    {
                        breakpoint: 1200,
                        options: {
                            chart: {
                                height: 140
                            }
                        }
                    }
                ]
            }
        },

        subscribersInMonth: {
            loaded: false,
            chartOptions: {
                chart: {
                    toolbar: { show: false },
                    zoom: { enabled: false },
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        top: 18,
                        left: 2,
                        blur: 5,
                        opacity: 0.2
                    },
                    offsetX: -5,
                    height: 300
                },
                stroke: {
                    curve: 'smooth',
                    width: 4
                },
                grid: {
                    borderColor: '#ebe9f1',
                    padding: {
                        top: -20,
                        bottom: 0,
                        left: 20
                    }
                },
                legend: {
                    show: true
                },
                colors: ['#7367f0', '#00E296'],
                markers: {
                    size: 3,
                    hover: {
                        size: 5
                    }
                },
                dataLabels: {
                    enabled: true
                },
                xaxis: {
                    labels: {
                        offsetY: 0
                    },
                    // axisTicks: {
                    //   show: false,
                    // },
                    categories: [],
                    // axisBorder: {
                    //   show: false,
                    // },
                    tickPlacement: 'on'
                },
                tooltip: {
                    x: { show: false }
                },
                responsive: [
                    {
                        breakpoint: 10000,
                        options: {
                            chart: {
                                height: 220
                            }
                        }
                    },
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 230
                            }
                        }
                    }
                ]
            },
            series: [
                {
                    name: 'Application',
                    data: []
                },
                {
                    name: 'Telegram',
                    data: []
                }
            ]
        },
        totalSubscribers: {
            loaded: false,
            chartOptions: {
                chart: {
                    type: 'radialBar'
                    // offsetY: -25,
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -150,
                        endAngle: 150,
                        hollow: {
                            margin: 0,
                            size: '70%',
                            background: '#fff',
                            image: undefined,
                            imageOffsetX: 0,
                            imageOffsetY: 0,
                            position: 'front',
                            dropShadow: {
                                enabled: true,
                                top: 3,
                                left: 0,
                                blur: 4,
                                opacity: 0.24
                            }
                        },
                        track: {
                            background: '#fff',
                            strokeWidth: '67%',
                            margin: 0, // margin is in pixels
                            dropShadow: {
                                enabled: true,
                                top: -3,
                                left: 0,
                                blur: 4,
                                opacity: 0.35
                            }
                        },
                        dataLabels: {
                            show: true,
                            name: { show: false },
                            value: {
                                formatter: function (val) {
                                    return parseInt(val) + '%';
                                },
                                color: '#111',
                                fontSize: '36px',
                                show: true
                            }
                        }
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.5,
                        gradientToColors: ['#ABE5A1'],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100]
                    }
                },
                stroke: {
                    lineCap: 'round'
                },
                responsive: [
                    {
                        breakpoint: 10000,
                        options: {
                            chart: {
                                height: 200
                            }
                        }
                    },
                    {
                        breakpoint: 1300,
                        options: {
                            chart: {
                                height: 230
                            }
                        }
                    },
                    {
                        breakpoint: 992,
                        options: {
                            chart: {
                                height: 230
                            }
                        }
                    }
                ]
            },
            data: [{}, {}]
        },

        charges: {
            2601: {
                name: 'Абонентская плата',
                loaded: false,
                series: [{}]
            },
            2602: {
                name: 'Второй шанс',
                loaded: false,
                series: [{}]
            },
            2603: {
                name: 'Третий шанс',
                loaded: false,
                series: [{}]
            },
            2604: {
                name: 'Премиум шанс 1',
                loaded: false,
                series: [{}]
            },
            2605: {
                name: 'Премиум шанс 2',
                loaded: false,
                series: [{}]
            },
            2606: {
                name: 'Премиум шанс 3',
                loaded: false,
                series: [{}]
            }
        },
        chargesChartOptions: {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                },
                id: 10
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 10
                }
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#7367f0']
                },
                background: {
                    enabled: true,
                    foreColor: '#fff',
                    padding: 4,
                    borderRadius: 2,
                    opacity: 0.9
                }
            },
            tooltip: {
                y: {
                    // formatter: (seriesName, a) => `${seriesName} ${a}`,
                }
            },
            legend: {
                // offsetY: 15,
                itemMargin: {
                    horizontal: 15,
                    vertical: 0
                }
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: []
            },
            colors: ['#00e296', '#F44336'],
            fill: {
                opacity: 1
            },
            responsive: [
                {
                    breakpoint: 10000,
                    options: {
                        chart: {
                            height: 255
                        }
                    }
                },
                {
                    breakpoint: 992,
                    options: {
                        chart: {
                            height: 580
                        },
                        plotOptions: {
                            bar: {
                                horizontal: true
                            }
                        },
                        xaxis: {
                            type: 'unset'
                        }
                    }
                }
            ]
        },
        chargedUncharged: {
            loaded: false,
            chartOptions: {
                chart: {
                    type: 'donut'
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '12px',
                        colors: ['#7367f0']
                    },
                    background: {
                        enabled: true,
                        // foreColor: '#7367f0',
                        padding: 5,
                        borderRadius: 2,
                        borderWidth: 1,
                        opacity: 1
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom'
                },
                grid: {
                    borderColor: '#EF3252',
                    padding: {
                        right: -20,
                        bottom: -10,
                        left: -20,
                        top: -5
                    }
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: false,
                                value: {
                                    offsetY: 0,
                                    formatter(val) {
                                        // eslint-disable-next-line radix
                                        return `${parseInt(val)}`;
                                    }
                                },
                                total: {
                                    show: false,
                                    offsetY: -5
                                }
                            }
                        }
                    }
                },
                labels: ['Charged', 'Uncharged'],
                colors: ['#00e296', '#F44336'],
                responsive: [
                    {
                        breakpoint: 10000,
                        options: {
                            chart: {
                                height: 215
                            }
                        }
                    }
                ]
            }
        },

        profits: {
            total: {
                loaded: false,
                series: [
                    {
                        name: 'Charged',
                        data: []
                    }
                ],
                overall: null
            },
            expense: {
                loaded: false,
                series: [
                    {
                        name: 'Charged',
                        data: []
                    }
                ],
                overall: null
            },
            revenue: {
                loaded: false,
                series: [
                    {
                        name: 'Charged',
                        data: []
                    }
                ],
                overall: null
            }
        },

        screenWidth: null,

        show: false,
        analytics: null
    }),
    computed: {
        computedTotalSubscribers() {
            if (!this.totalSubscribers.data.total) return [0];
            return [(100 / +this.totalSubscribers.data.total) * +this.totalSubscribers.data.active];
        }
    },
    methods: {
        formatToPrice,
        async getSubscribersAmount() {
            const { data } = await api.statistics.getTotalSubscribers();
            this.totalSubscribers.data = data;
            this.totalSubscribers.loaded = true;
        },

        async getSubscribersVerification() {
            await api.statistics
                .getSubscribersVerification()
                .then(({ data }) => {
                    this.subscribersVerification.total = data.verified + data.unverified;
                    this.subscribersVerification.verified = data.verified;
                    this.subscribersVerification.unverified = data.unverified;

                    this.subscribersVerification.chartOptions.plotOptions.pie.donut.labels.total.formatter =
                        () => {
                            return this.subscribersVerification.total;
                        };
                })
                .then(() => {
                    this.subscribersVerification.loaded = true;
                });
        },

        async getPaynetStatistics() {
            await api.statistics
                .getPaynetStats()
                .then(({ data }) => {
                    this.paynetStats.total = +data.deposit + +data.expenses;
                    this.paynetStats.deposit = +data.deposit;
                    this.paynetStats.expenses = +data.expenses;

                    this.paynetStats.chartOptions.plotOptions.pie.donut.labels.total.formatter =
                        () => {
                            return this.paynetStats.total;
                        };
                })
                .then(() => {
                    this.paynetStats.loaded = true;
                });
        },

        async getSubscribersInMonth() {
            const dates = [];
            await api.statistics
                .getNewSubscribersAmountInMonth()
                .then(({ data }) => {
                    data.map((item) => {
                        const date = new Date(item.date);
                        this.subscribersInMonth.chartOptions.xaxis.categories.unshift(
                            `${prefixZeroToNum(date.getDate())}.${prefixZeroToNum(
                                date.getMonth() + 1
                            )}`
                        );
                        this.subscribersInMonth.series[0].data.unshift(item.app_count);
                        this.subscribersInMonth.series[1].data.unshift(item.tg_count);
                    });
                })
                .then(() => {
                    this.subscribersInMonth.loaded = true;
                });
        },

        async getChargesSingle(id) {
            await api.statistics.getCharge(id).then(({ data }) => {
                if (!this.chargesChartOptions.xaxis.categories.length)
                    this.sortChargesDateByScreen(data.date);
                this.charges[id].series[0] = {
                    name: 'Charged',
                    data: data.charged
                };
                this.charges[id].series[1] = {
                    name: 'Uncharged',
                    data: data.uncharged
                };
                this.charges[id].price = data.price;
            });
        },

        async getDailyChargesSingle(id) {
            await api.statistics.getDailyCharge(id).then(({ data }) => {
                this.charges[id].charged = data.charged.length
                    ? this.accumulateNumbers(data.charged)
                    : 0;
                this.charges[id].uncharged = data.uncharged.length
                    ? this.accumulateNumbers(data.uncharged)
                    : 0;
            });
        },

        async getChargesAll() {
            for (const key of Object.keys(this.charges)) {
                try {
                    await this.getChargesSingle(key);
                    await this.getDailyChargesSingle(key);
                } catch (error) {
                } finally {
                    this.charges[key].loaded = true;
                }
            }
        },

        async getTotalGifts() {
            await api.statistics
                .getTotalGifts()
                .then(({ data }) => {
                    data.items.forEach((item) => {
                        this.totalStatistics.gifts.chartOptions.xaxis.categories.unshift(item.date);
                        this.totalStatistics.gifts.series[0].data.push(item.count);
                    });
                    this.totalStatistics.gifts.total = data.total;
                })
                .then(() => {
                    this.totalStatistics.gifts.loaded = true;
                });
        },

        async getTotalInvoices() {
            await api.statistics
                .getTotalInvoices()
                .then(({ data }) => {
                    this.totalStatistics.invoices.chartOptions.xaxis.categories = data.date;
                    this.totalStatistics.invoices.series[0].data = data.charged;
                    this.totalStatistics.invoices.series[1].data = data.uncharged;
                    this.totalStatistics.invoices.overall = data.overall;
                })
                .then(() => {
                    this.totalStatistics.invoices.loaded = true;
                });
        },

        async getProfitsTotal() {
            await api.statistics.getTotalProfitsTotal().then(({ data }) => {
                this.profits.total.series[0].data = data.charges;
                this.profits.total.overall = data.overall;
                this.profits.total.loaded = true;
            });
        },

        async getProfitsExpense() {
            await api.statistics.getTotalProfitsExpense().then(({ data }) => {
                this.profits.expense.series[0].data = data.charges;
                this.profits.expense.overall = data.overall;
                this.profits.expense.loaded = true;
            });
        },

        async getProfitsRevenue() {
            await api.statistics.getTotalProfitsRevenue().then(({ data }) => {
                this.profits.revenue.series[0].data = data.charges;
                this.profits.revenue.overall = data.overall;
                this.profits.revenue.loaded = true;
            });
        },

        sortChargesByScreen(data) {
            if (this.screenWidth < 576) {
                const newData = JSON.parse(JSON.stringify(data));
                return newData.map((item) => {
                    item.data = item.data.splice(15, 30);
                    return item;
                });
            }
            return data;
        },

        sortChargesDateByScreen(datesArray) {
            datesArray = datesArray.map((date) => {
                date = new Date(date);
                return `${prefixZeroToNum(date.getDate())}.${prefixZeroToNum(date.getMonth() + 1)}`;
            });
            if (this.screenWidth < 576) {
                this.chargesChartOptions.xaxis.categories = datesArray.splice(15, 30);
            } else {
                this.chargesChartOptions.xaxis.categories = datesArray;
            }
        },

        accumulateNumbers(arr) {
            return arr.reduce((acc, num) => {
                return acc + num;
            }, 0);
        },

        calculateChargesPrice(num, price) {
            return this.abbreviateNumber(num * ((price / 115) * 100).toFixed(3));
        },

        abbreviateNumber(number) {
            return abbreviateNumber(Number(number));
        },

        numberFormat(number) {
            return numberFormat(number);
        },

        async getAnalyticsCards() {
            try {
                const response = await api.coin.fetchCardsAmount();
                if (response.data.result) {
                    this.analytics = response.data.result;
                }
            } catch (e) {
                this.$toast(e?.response?.data?.message ?? e.message, {
                    type: 'error'
                });
            }
        }
    },
    mounted() {
        setTimeout(() => {
            Promise.all([
                this.getTotalGifts(),
                this.getTotalInvoices(),
                this.getSubscribersAmount(),
                this.getSubscribersVerification(),
                this.getPaynetStatistics(),
                this.getSubscribersInMonth(),
                this.getChargesAll(),
                this.getProfitsTotal(),
                this.getProfitsExpense(),
                this.getProfitsRevenue(),
                this.getAnalyticsCards()
            ]);
        }, 1000);

        this.screenWidth = window.screen.width;

        window.addEventListener('resize', ({ target }) => {
            this.screenWidth = target.screen.width;
        });
    }
};
</script>

<template>
    <div>
        <!--    THIN CARDS    -->
        <div class="thin__cards mb-2 col-12 px-0">
            <b-card
                class="thin__card-body d-flex align-items-center justify-content-between col-12"
            >
                <div>
                    <p class="thin__card-amount mb-0">
                        {{ formatToPrice(analytics.orders_count) }}
                    </p>
                    <span class="thin__card-name">{{ $t('coin_analytics.total_orders') }}</span>
                </div>
                <div class="thin__card-icon shopping-cart-bg">
                    <feather-icon class="shopping-cart-icon" icon="ShoppingCartIcon" size="26" />
                </div>
            </b-card>

            <b-card
                class="thin__card-body d-flex align-items-center justify-content-between col-12"
            >
                <div>
                    <p class="thin__card-amount mb-0">{{ formatToPrice(analytics.ordered) }}</p>
                    <span class="thin__card-name">{{ $t('coin_analytics.ordered') }}</span>
                </div>
                <div class="thin__card-icon clock-bg">
                    <feather-icon class="clock-icon" icon="ClockIcon" size="26" />
                </div>
            </b-card>

            <b-card
                class="thin__card-body d-flex align-items-center justify-content-between col-12"
            >
                <div>
                    <div class="d-flex align-items-center">
                        <img
                            src="@/assets/images/icons/coin.png"
                            :width="18"
                            :height="18"
                            alt="coin.png"
                            style="margin-right: 0.5rem"
                        />
                        <p class="thin__card-amount mb-0">
                            {{ formatToPrice(analytics.expense) }}
                        </p>
                    </div>
                    <span class="thin__card-name">{{ $t('coin_analytics.total_expenses') }}</span>
                </div>
                <div class="thin__card-icon trending-down-bg">
                    <feather-icon class="trending-down-icon" icon="TrendingDownIcon" size="26" />
                </div>
            </b-card>
        </div>
        <b-row>
            <template v-if="hasAccess('statistics')">
                <b-col lg="3" md="6" cols="12" class="mb-2">
                    <b-card no-body>
                        <b-card-body class="pb-0">
                            <div class="d-flex">
                                <b-avatar class="mb-1" :variant="`light-primary`" size="45">
                                    <feather-icon size="21" icon="UsersIcon" />
                                </b-avatar>
                                <div class="d-flex flex-column ml-1">
                                    <span>Subscribers</span>
                                    <h2 class="mb-25 font-weight-bolder">
                                        {{ abbreviateNumber(totalSubscribers.data.total) }}
                                    </h2>
                                </div>
                            </div>
                        </b-card-body>
                        <progress-linear v-if="!subscribersInMonth.loaded" />
                        <apexchart
                            v-else
                            class="mt-auto"
                            type="area"
                            :options="totalStatistics.subscribers.chartOptions"
                            :series="subscribersInMonth.series"
                        />
                    </b-card>
                </b-col>
                <b-col lg="3" md="6" cols="12" class="mb-2">
                    <b-card no-body>
                        <b-card-body class="pb-0">
                            <div class="d-flex">
                                <b-avatar class="mb-1" :variant="`light-primary`" size="45">
                                    <feather-icon size="21" icon="GiftIcon" />
                                </b-avatar>
                                <div class="d-flex flex-column ml-1">
                                    <span>Gifts</span>
                                    <h2 class="mb-25 font-weight-bolder">
                                        {{ abbreviateNumber(totalStatistics.gifts.total) }}
                                    </h2>
                                </div>
                            </div>
                        </b-card-body>
                        <progress-linear v-if="!totalStatistics.gifts.loaded" />
                        <apexchart
                            v-else
                            class="mt-auto"
                            type="area"
                            :options="totalStatistics.subscribers.chartOptions"
                            :series="totalStatistics.gifts.series"
                        />
                    </b-card>
                </b-col>
                <b-col lg="3" md="6" cols="12" class="mb-2">
                    <b-card no-body>
                        <b-card-body class="pb-0">
                            <div class="d-flex">
                                <b-avatar class="mb-1" :variant="`light-primary`" size="45">
                                    <feather-icon size="21" icon="UsersIcon" />
                                </b-avatar>
                                <div class="d-flex flex-column ml-1">
                                    <span>Invoices</span>
                                    <h2 class="mb-25 font-weight-bolder">
                                        {{ abbreviateNumber(totalStatistics.invoices.overall) }}
                                    </h2>
                                </div>
                            </div>
                        </b-card-body>
                        <progress-linear v-if="!totalStatistics.invoices.loaded" />
                        <apexchart
                            v-else
                            class="mt-auto"
                            type="area"
                            :options="totalStatistics.invoices.chartOptions"
                            :series="totalStatistics.invoices.series"
                        />
                    </b-card>
                </b-col>
                <b-col lg="3" md="6" cols="12" class="mb-2">
                    <b-card no-body>
                        <b-card-body class="pb-0">
                            <h5>Verifications</h5>
                        </b-card-body>
                        <progress-linear v-if="!subscribersVerification.loaded" />
                        <div v-else class="statistics-radial-bar">
                            <apexchart
                                :options="subscribersVerification.chartOptions"
                                :series="[
                                    subscribersVerification.verified,
                                    subscribersVerification.unverified
                                ]"
                            />
                            <div class="d-flex flex-column px-2 pb-1">
                                <span class="mr-1 text-nowrap"
                                    >Verified:
                                    <strong>{{
                                        numberFormat(subscribersVerification.verified)
                                    }}</strong></span
                                >
                                <span class="text-nowrap"
                                    >Unverified:
                                    <strong>{{
                                        numberFormat(subscribersVerification.unverified)
                                    }}</strong></span
                                >
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </template>

            <template v-if="hasAccess('profits')">
                <b-col lg="3" md="6" cols="12" class="mb-2">
                    <b-card no-body>
                        <b-card-body class="pb-0">
                            <div class="d-flex">
                                <b-avatar class="mb-1" :variant="`light-primary`" size="45">
                                    <feather-icon size="21" icon="TrendingDownIcon" />
                                </b-avatar>
                                <div class="d-flex flex-column ml-1">
                                    <span>Profit Total</span>
                                    <h2 class="mb-25 font-weight-bolder">
                                        {{ abbreviateNumber(profits.total.overall) }}
                                    </h2>
                                </div>
                            </div>
                        </b-card-body>
                        <progress-linear v-if="!profits.total.loaded" />
                        <apexchart
                            v-else
                            class="mt-auto"
                            type="area"
                            :options="totalStatistics.subscribers.chartOptions"
                            :series="profits.total.series"
                        />
                    </b-card>
                </b-col>
                <b-col lg="3" md="6" cols="12" class="mb-2">
                    <b-card no-body>
                        <b-card-body class="pb-0">
                            <div class="d-flex">
                                <b-avatar class="mb-1" :variant="`light-primary`" size="45">
                                    <feather-icon size="21" icon="WindIcon" />
                                </b-avatar>
                                <div class="d-flex flex-column ml-1">
                                    <span>Profit Expense</span>
                                    <h2 class="mb-25 font-weight-bolder">
                                        {{ abbreviateNumber(profits.expense.overall) }}
                                    </h2>
                                </div>
                            </div>
                        </b-card-body>
                        <progress-linear v-if="!profits.expense.loaded" />
                        <apexchart
                            v-else
                            class="mt-auto"
                            type="area"
                            :options="totalStatistics.subscribers.chartOptions"
                            :series="profits.expense.series"
                        />
                    </b-card>
                </b-col>
                <b-col lg="3" md="6" cols="12" class="mb-2">
                    <b-card no-body>
                        <b-card-body class="pb-0">
                            <div class="d-flex">
                                <b-avatar class="mb-1" :variant="`light-primary`" size="45">
                                    <feather-icon size="21" icon="AnchorIcon" />
                                </b-avatar>
                                <div class="d-flex flex-column ml-1">
                                    <span>Profit Revenue</span>
                                    <h2 class="mb-25 font-weight-bolder">
                                        {{ abbreviateNumber(profits.revenue.overall) }}
                                    </h2>
                                </div>
                            </div>
                        </b-card-body>
                        <progress-linear v-if="!profits.revenue.loaded" />
                        <apexchart
                            v-else
                            class="mt-auto"
                            type="area"
                            :options="totalStatistics.invoices.chartOptions"
                            :series="profits.revenue.series"
                        />
                    </b-card>
                </b-col>
            </template>
            <b-col v-if="hasAccess('statistics')" lg="3" md="4" cols="12" class="mb-2">
                <b-card no-body>
                    <b-card-body class="pb-0">
                        <h5>Total Subscribers</h5>
                        <progress-linear v-if="!totalSubscribers.loaded" />
                        <div v-else>
                            <apexchart
                                :options="totalSubscribers.chartOptions"
                                :series="computedTotalSubscribers"
                            />
                            <div class="d-flex flex-column">
                                <span class="mr-1 text-nowrap"
                                    >Active:
                                    <strong>{{
                                        numberFormat(totalSubscribers.data.active)
                                    }}</strong></span
                                >
                                <span class="text-nowrap"
                                    >Inactive:
                                    <strong>{{
                                        numberFormat(totalSubscribers.data.inactive)
                                    }}</strong></span
                                >
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <template v-if="hasAccess('statistics')">
                <b-col lg="9" md="8" xs="12" class="mb-2">
                    <div class="card">
                        <h3 class="m-2">Subscribers</h3>
                        <progress-linear v-if="!subscribersInMonth.loaded" />
                        <apexchart
                            v-else
                            type="line"
                            :options="subscribersInMonth.chartOptions"
                            :series="subscribersInMonth.series"
                        />
                    </div>
                </b-col>
                <b-col lg="3" md="4" cols="12" class="mb-2">
                    <b-card no-body>
                        <b-card-body class="pb-0 flex-grow-0">
                            <h5>Paynet</h5>
                        </b-card-body>
                        <progress-linear v-if="!paynetStats.loaded" />
                        <div v-else class="statistics-radial-bar">
                            <apexchart
                                :options="paynetStats.chartOptions"
                                :series="[paynetStats.deposit, paynetStats.expenses]"
                            />
                            <div class="d-flex flex-column px-2 pb-1">
                                <span class="mr-1 text-nowrap"
                                    >Deposit:
                                    <strong>{{ numberFormat(paynetStats.deposit) }}</strong></span
                                >
                                <span class="text-nowrap"
                                    >Expenses:
                                    <strong>{{ numberFormat(paynetStats.expenses) }}</strong></span
                                >
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </template>

            <div
                v-if="hasAccess('charges')"
                v-for="(charge, index) in charges"
                :key="index"
                class="d-flex flex-wrap w-100 mb-2"
            >
                <b-col lg="9" md="8" cols="12">
                    <div class="card h-auto">
                        <h4 class="mx-2 mt-2 mb-1">{{ charge.name }}</h4>
                        <progress-linear v-if="!charge.loaded" />
                        <apexchart
                            v-else-if="chargesChartOptions.xaxis.categories.length"
                            :options="{
                                chart: {
                                    type: 'bar',
                                    toolbar: {
                                        show: false
                                    },
                                    id: 10
                                },
                                plotOptions: {
                                    bar: {
                                        horizontal: false,
                                        borderRadius: 10
                                    }
                                },
                                dataLabels: {
                                    enabled: true,
                                    style: {
                                        colors: ['#7367f0']
                                    },
                                    background: {
                                        enabled: true,
                                        foreColor: '#fff',
                                        padding: 4,
                                        borderRadius: 2,
                                        opacity: 0.9
                                    }
                                },
                                tooltip: {
                                    y: {
                                        formatter: (num) => calculateChargesPrice(num, charge.price)
                                    }
                                },
                                legend: {
                                    // offsetY: 15,
                                    itemMargin: {
                                        horizontal: 15,
                                        vertical: 0
                                    }
                                },
                                stroke: {
                                    show: true,
                                    width: 2,
                                    colors: ['transparent']
                                },
                                xaxis: {
                                    categories: chargesChartOptions.xaxis.categories
                                },
                                colors: ['#00e296', '#F44336'],
                                fill: {
                                    opacity: 1
                                },
                                responsive: [
                                    {
                                        breakpoint: 10000,
                                        options: {
                                            chart: {
                                                height: 255
                                            }
                                        }
                                    },
                                    {
                                        breakpoint: 992,
                                        options: {
                                            chart: {
                                                height: 580
                                            },
                                            plotOptions: {
                                                bar: {
                                                    horizontal: true
                                                }
                                            },
                                            xaxis: {
                                                type: 'unset'
                                            }
                                        }
                                    }
                                ]
                            }"
                            :series="sortChargesByScreen(charge.series)"
                        />
                    </div>
                </b-col>
                <b-col lg="3" md="4" cols="12">
                    <div class="card h-auto">
                        <h5 class="mx-2 mt-2 mb-0">{{ charge.name }} today</h5>
                        <progress-linear v-if="!charge.loaded" />
                        <template v-else>
                            <apexchart
                                height="200"
                                :options="chargedUncharged.chartOptions"
                                :series="[charge.charged, charge.uncharged]"
                                class="my-1"
                            />
                            <div class="d-flex flex-column px-2 pb-1">
                                <span class="text-nowrap"
                                    >Charged:
                                    <strong>{{ numberFormat(charge.charged) }}</strong></span
                                >
                                <span class="text-nowrap"
                                    >Uncharged:
                                    <strong>{{ numberFormat(charge.uncharged) }}</strong></span
                                >
                                <span class="text-nowrap"
                                    >Total:
                                    <strong>{{
                                        numberFormat(charge.charged + charge.uncharged)
                                    }}</strong></span
                                >
                            </div>
                        </template>
                    </div>
                </b-col>
            </div>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
.card {
    margin-bottom: 0;
    height: 100%;
}

.thin__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 26px;

    //grid-template-columns: 1fr;
    //grid-template-rows: repeat(3, 1fr);
    //grid-column-gap: 0px;
    //grid-row-gap: 0px;
}

.thin__card {
    &-body {
        width: 100%;

        & .card-body {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &-amount {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }

    &-name {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
    }

    &-icon {
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
    }
}

.shopping-cart-bg {
    background: rgba(115, 103, 240, 0.08);

    & svg {
        color: #7367f0;
    }
}

.clock-bg {
    background: rgba(255, 159, 67, 0.08);

    & svg {
        color: #ff9f43;
    }
}

.trending-down-bg {
    background: rgba(234, 84, 85, 0.08);

    & svg {
        color: #ea5455;
    }
}

@media only screen and (max-width: 767px) {
    .thin__cards {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 1.5rem;
    }
}
</style>
